import React from "react";
import Contact from "./contact-form";

const Details = () => (
  <>
    <Contact />
  </>
);

export default Details;
