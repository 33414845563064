/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { NetlifyForm, Honeypot } from "react-netlify-forms";

import FormInputs from "./form-inputs";
import FormControls from "./form-controls";
import SuccessMessage from "./success-message";
import ErrorMessage from "./error-message";

const Form = () => (
  <NetlifyForm name="Contact" action="/thanks" honeypotName="bot-field">
    {({ handleChange, success, error, values }) => (
      <section sx={{ display: `flex`, flexDirection: `column` }}>
        {!success ? (
          <>
            {error && <ErrorMessage />}

            <Honeypot
              name="bot-field"
              label="Leave the following field empty:"
            />

            <FormInputs
              onChange={handleChange}
              sx={{
                fontFamily: `inherit`,
                fontSize: `inherit`,
                transition: `all 0.25s linear 0s`,
                position: `relative`,
                ":hover": { borderColor: `primary` },
                ":focus": {
                  borderColor: `primary`,
                  outline: `0`,
                },
              }}
            />
            <FormControls />
          </>
        ) : (
          <SuccessMessage values={values.name} />
        )}
      </section>
    )}
  </NetlifyForm>
);

export default Form;
