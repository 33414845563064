import React from "react";
import { Themed } from "theme-ui";

const ErrorMessage = () => (
  <>
    <h2>Sorry, there seems to be an error 😨 </h2>
    <p>
      You can contact me directly at{" "}
      <Themed.a href="mailto:email@example.com" aria-label="Email">
        Leilameyerdesign@gmail.com
      </Themed.a>{" "}
      or send me message on{" "}
      <Themed.a
        href="https://www.linkedin.com/in/leilakmeyer/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Linkedin"
      >
        LinkedIn
      </Themed.a>
      .
    </p>
  </>
);

export default ErrorMessage;
