import React from "react";
import { Themed } from "theme-ui";

const Header = () => (
  <>
    <Themed.h2>Get in touch</Themed.h2>
  </>
);

export default Header;
