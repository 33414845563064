import { graphql } from "gatsby"
import ProjectsComponent from "./projects"

export default ProjectsComponent

export const query = graphql`
  query {
    allProject(sort: { fields: date, order: DESC }) {
      nodes {
        color
        slug
        service
        client
        medium
        title
        cover {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 90, aspectRatio: 1)
          }
        }
      }
    }
  }
`
