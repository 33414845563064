/** @jsx jsx */
import React from "react";
import { Themed, jsx } from "theme-ui";

const Intro = () => (
  <>
    <Themed.p sx={{ mt: 2, mb: 4 }}>
      If you want to get in touch for your next project or you still have
      questions left, do not hesitate to start a chat.
    </Themed.p>
  </>
);

export default Intro;
