/** @jsx jsx */
import React from "react";
import { jsx, Themed } from "theme-ui";
import useSiteMetadata from "../../hooks/use-site-metadata";

const Contact = () => {
  const site = useSiteMetadata();
  const { email, author } = site;

  return (
    <>
      <Themed.h3 sx={{ mt: 0 }}>LinkedIn</Themed.h3>
      <span>
        <Themed.a
          href="https://www.linkedin.com/in/leilakmeyer/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          https://www.linkedin.com/in/leilakmeyer/
        </Themed.a>
      </span>

      <Themed.h3>Twitter</Themed.h3>
      <span>
        <Themed.a
          href="https://twitter.com/leilamdesigns"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          https://twitter.com/leilamdesigns
        </Themed.a>
      </span>

      <Themed.h3>Email</Themed.h3>
      <span>
        <Themed.a href="mailto:email@example.com" aria-label="Email">
          Leilameyerdesign@gmail.com
        </Themed.a>
      </span>
    </>
  );
};

export default Contact;
