/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Button } from "theme-ui";

const FormControls = () => (
  <Button
    sx={{
      variant: `buttons.contact`,
      fontWeight: `semibold`,
      alignSelf: [`center`, null, `flex-start`, null],
      my: [2, null, 3, null],
      px: [4, 3, null, null],
      py: [3, 2, null, null],
    }}
    type="submit"
  >
    Send message
  </Button>
);

export default FormControls;
