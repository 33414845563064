import React from "react";

const SuccessMessage = ({ name }) => {
  return (
    <>
      <h2>Message Received 💫</h2>
      <p>Thank you for contacting me {name}! I will get back to you shortly.</p>
    </>
  );
};

export default SuccessMessage;
