/** @jsx jsx */
import * as React from "react";
import { jsx, useColorMode, useThemeUI } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { animated, useSpring, config } from "react-spring";
import { rgba } from "polished";
import { Box } from "theme-ui";

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

type FullHeroProps = {
  children: React.ReactNode;
  color?: string;
};


// MAKE SURE TO CREDIT https://unsplash.com/@shaktirajpurohit

const FullHero = ({ children, color = `` }: FullHeroProps) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "fullpagehero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 90
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `);

  const image = getImage(data.file);

  const contentProps = useSpring({
    config: config.slow,
    delay: 500,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  const [colorMode] = useColorMode();
  const {
    theme: { rawColors },
  } = useThemeUI();

  const bg =
    colorMode === `light`
      ? (rawColors?.background as string)
      : (rawColors?.modes?.dark?.background as string);
  const shade = rgba(bg, 0.4);


  return (
    <section sx={{
      height: `calc(100vh - 92px)`, display: "grid", position: `relative`, "&:before": {
        content: `""`,
        height: `100%`,
        left: 0,
        top: 0,
        position: `absolute`,
        width: `100%`,
        mixBlendMode: colorMode === `light` ? `overlay` : `soft-light`,
        zIndex: 2,
        background: color
          ? `linear-gradient(to top, rgba(0, 0, 0, 0) 0%, ${rgba(
            color!,
            1
          )} 100%)`
          : null,
      },
      "&:after": {
        backfaceVisibility: `hidden`,
        content: `""`,
        height: `100%`,
        left: 0,
        top: 0,
        position: `absolute`,
        width: `100%`,
        background: `linear-gradient(to bottom, ${shade} 0%, ${bg} 100%),
    linear-gradient(135deg, ${shade} 40%, ${bg} 100%), linear-gradient(-135deg, ${shade} 40%, ${bg} 100%)`,
      },
    }}>
      <StaticImage
        sx={{
          gridArea: "1/1",

        }}
        layout="fullWidth"
        loading="eager"
        placeholder="dominantColor"
        alt=""
        src={"../../images/fullpagehero.jpg"}
        formats={["auto", "webp", "avif"]}
      />
      <animated.div
        style={contentProps}
        sx={{
          gridArea: `1/1`,
          position: `relative`,
          placeItems: `center`,
          display: `grid`,
          zIndex: `100`
        }}
      >
        <Box sx={{ transform: `translateY(-40px)`, }}>{children}</Box>
      </animated.div>
    </section>
  );
};

export default FullHero;
