/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Textarea, Input, Label } from "theme-ui";
import { alpha } from "@theme-ui/color";

const LabeledInput = ({ as = Input, label, ...props }) => {
  const name = label.toLowerCase();
  const Component = as;
  return (
    <>
      <Label sx={{ pb: 1 }} htmlFor={name}>
        {label}
      </Label>
      <div
        sx={{
          mb: 3,
          position: `relative`,
          "::before": {
            content: `""`,
            position: `absolute`,
            top: `0`,
            right: `0`,
            bottom: `0`,
            left: `0`,
            opacity: `0`,
            transition: `all 0.35s linear 0s`,
            boxShadow: (theme) => {
              return `inset 0 1px 1px rgba(0, 0, 0, 0.08),
                    0 0 6px ${alpha("primary", 0.5)(theme)}`;
            },
          },
          ":focus-within::before": { opacity: `1` },
        }}
      >
        <Component name={name} id={name} {...props} />
      </div>
    </>
  );
};

const FormInput = ({ ...props }) => (
  <>
    <LabeledInput
      label="Name"
      type="text"
      required
      placeholder="Enter your name"
      {...props}
    />

    <LabeledInput
      label="Email"
      type="email"
      required
      placeholder="Enter your email"
      {...props}
    />

    <LabeledInput
      label="Message"
      as={Textarea}
      required
      minLength={10}
      rows={5}
      placeholder="Leave a message here"
      {...props}
    />
  </>
);

export default FormInput;
