/** @jsx jsx */
import * as React from "react"
import { jsx, Container, Themed, Heading, Paragraph } from "theme-ui"
import { Box } from "theme-ui"
import { useTrail } from "react-spring"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Layout from "./layout"
import ProjectItem from "./project-item"
import ContactPage from "../contact/contact-page";
import FullHero from './full-hero'

type ProjectsProps = {
  projects: {
    color: string
    slug: string
    title: string
    service: string
    client: string
    medium: string
    cover: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }[]
  [key: string]: any
}

const Projects = ({ projects }: ProjectsProps) => {
  const trail = useTrail(projects.length, {
    from: { height: `0%` },
    to: { height: `100%` },
  })

  if (projects.length === 0) {
    return (
      <Layout>
        <Container>
          <Themed.p>
            Hi!{` `}
            <span role="img" aria-label="Wave emoji">
              👋
            </span>
            {` `}
            <br />
            You currently don't have any content in your <i>projects</i> folder
          </Themed.p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <FullHero>
        <Heading as="h1" variant="styles.hero" >
          Hi, I'm Leila ✨
        </Heading>
        <Paragraph variant="styles.heroDesc" >
          A Graphic Designer based in Fresno, CA
        </Paragraph>
      </FullHero>

      <Box id="works" as="main" variant="layout.main" sx={{
        display: `grid`,
        gridTemplateColumns: `repeat(auto-fit, minmax(320px, 1fr))`,
        width: `100%`,
      }}>
        {trail.map((style, index) => (
          <ProjectItem style={style} eager={index === 0} node={projects[index]} key={projects[index].slug} />
        ))}
      </Box>
      <Box id="contact" variant="layout.contact">
        <ContactPage />
      </Box>
    </Layout>
  )
}

export default Projects
